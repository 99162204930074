/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import { Button, Icon } from '@chakra-ui/react';
import { AddCircleIcon } from '@himarley/unity';
import {
  getMassNotifications,
  subscribeSocket,
  deleteNotification,
  downloadErrorCSV,
  downloadCSVTemplate,
  setMessageCopied,
  downloadTranscript,
} from '../../actions/massNotifications';
import MassNotificationModal from './MassNotificationModal/MassNotificationModal';
import MassNotificationVerificationModal from './MassNotificationVerificationModal/MassNotificationVerificationModal';
import MassNotificationViewModal from './MassNotificationViewModal/MassNotificationViewModal';
import MassNotificationDeleteVerifyModal from './MassNotificationDeleteVerifyModal/MassNotificationDeleteVerifyModal';

// eslint-disable-next-line import/no-named-as-default
import SortTable from '../elements/table/SortTable/SortTable';
import TableHeader from '../elements/table/SortTable/TableHeader/TableHeader';
import MassNotificationRow from './MassNotificationRow/MassNotificationRow';
import { formatDate } from '../../helpers/datetime';
import { massNotificationType } from '../../models/marleyTypes';

import DocumentIcon from '../../../images/icons/document.svg';
import ArchiveIcon from '../../../images/icons/archive.svg';
import ChatIcon from '../../../images/icons/chat-bubbles.svg';
import CheckIcon from '../../../images/icons/check.svg';
import './MassNotifications.less';

import Panel from '../Panel/Panel';

export const NOTIFICATION_STATUS_HASH = {
  INVALID_CSV: <span className="errorStatus">CSV is in an invalid format</span>,
  VALIDATION_FAILED: <span className="errorStatus">Unexpected failure during validation</span>,
  VALIDATION_COMPLETE:
  <span
    className="ready-to-send"
  >
    Ready To Send
  </span>,
  VALIDATING:
  <span className="validating">
    <span>Validating</span>
    <div className="validatorSpinner">
      <Loader
        type="ThreeDots"
        color="#44C7E9"
        height="30"
        width="30"
      />
    </div>
  </span>,
  CREATED: 'Validation starting shortly',
  PROCESSING:
  <span className="validating">
    <span>Processing</span>
    <div className="validatorSpinner">
      <Loader
        type="ThreeDots"
        color="#44C7E9"
        height="30"
        width="30"
      />
    </div>
  </span>,
  SENDING:
  <span className="validating">
    <span>Sending</span>
    <div className="validatorSpinner">
      <Loader
        type="ThreeDots"
        color="#44C7E9"
        height="30"
        width="30"
      />
    </div>
  </span>,
  CREATING_MESSAGES:
  <span className="validating">
    <span>Creating Messages</span>
    <div className="validatorSpinner">
      <Loader
        type="ThreeDots"
        color="#44C7E9"
        height="30"
        width="30"
      />
    </div>
  </span>,
  SENT:
  <span className="delivered">
    Delivered
    {' '}
    <CheckIcon />
  </span>,
  TRANSCRIPT_CREATED:
  <span className="delivered">
    Delivered
    {' '}
    <CheckIcon />
  </span>,
  FAILED: <span className="errorStatus">Unexpected failure occured</span>,
  FAILED_TO_SEND: <span className="errorStatus">Failed To Send</span>,
  PARTIALLY_FAILED: <span className="delivered">Some Messages Failed</span>,
  MAX_ENTRIES_SURPASSED: <span className="errorStatus">Surpassed Max Entries</span>,
  VALIDATION_TIMEOUT: <span className="errorStatus">Validation Timeout</span>,
  NO_VALID_ENTRIES: 'No entries in CSV were valid',
  CREATEING_MESSAGES:
  <span className="validating">
    <span>Processing</span>
    <div className="validatorSpinner">
      <Loader
        type="ThreeDots"
        color="#44C7E9"
        height="30"
        width="30"
      />
    </div>
  </span>,
};

/* Possible status states */
/*
  INVALID_CSV:INVALID_FORMAT
  INVALID_CSV:MISSING_REQUIRED_FIELDS
  VALIDATION_FAILED:FAILED_TO_VALIDATE_ENTRIES
  VALIDATION_FAILED:FAILED_TO_WRITE_ERROR_REPORT
  VALIDATION_FAILED:FAILED_TO_ADD_ERROR_REPORT_TO_ENTRY
  VALIDATION_COMPLETE
  VALIDATING
  CREATED
*/

class MassNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteVerificationModal: false,
      showVerificationModal: false,
      showViewModal: false,
      tableColumns: [
        {
          sort: true, id: 'notification-name', label: 'Notification Name', class: 'class-number-head', location: 'Title',
        },
        {
          sort: true,
          id: 'date',
          label: 'Date Created',
          location: 'Date',
          numericSort: true,
          sortType: 'date',
          format: ({ value }) => (<div>{formatDate(value, 'just-date')}</div>),
        },
        {
          sort: true,
          id: 'date',
          label: 'Scheduled Date',
          location: 'ScheduledSendDate',
          numericSort: true,
          sortType: 'date',
          format: ({ value }) => (value
            ? <div>{formatDate(value)}</div>
            : <div>-</div>),
        },
        {
          sort: true,
          id: 'sent-messages',
          label: 'Sent Messages',
          location: 'MessagesSent',
        },
        {
          sort: true,
          id: 'total-messages',
          label: 'Total Messages',
          location: 'MessagesTotal',
        },
        {
          label: 'Status',
          location: 'Status',
          id: 'status',
          format: ({ value, id }) => {
            const { massNotificationList } = this.props;
            const status = value && value.split(':')[0];
            const currMN = massNotificationList.find((curr) => curr.Id === id);

            if (status === 'CREATING_MESSAGES' && currMN.BatchesLeft && currMN.BatchesLeft !== 0) {
              return (
                <span>
                  <span className="validating">
                    <span>
                      {currMN.BatchesLeft * 20}
                      {' '}
                      Msgs to Create
                    </span>
                    <div className="validatorSpinner">
                      <Loader
                        type="ThreeDots"
                        color="#44C7E9"
                        height="30"
                        width="30"
                      />
                    </div>
                  </span>
                </span>
              );
            }

            if (currMN.ScheduledSendDate) {
              return (
                <span>
                  {
                    status === 'VALIDATION_COMPLETE'
                      ? <div className="ready-to-send">Scheduled</div>
                      : _.get(NOTIFICATION_STATUS_HASH, status, '') || (<div className="errorStatus">{status}</div>)
                  }
                </span>
              );
            }

            return (
              <span>
                {
                  status === 'VALIDATION_COMPLETE'
                    ? (
                      <div
                        onClick={() => this.verifyNotification(id)}
                        onKeyDown={() => {}}
                        className="sendOverlay"
                        type="outline"
                        role="button"
                        tabIndex={0}
                      >
                        {_.get(NOTIFICATION_STATUS_HASH, status, '') || (<div className="errorStatus">{status}</div>)}
                      </div>
                    )
                    : _.get(NOTIFICATION_STATUS_HASH, status, '') || (<div className="errorStatus">{status}</div>)
                }
              </span>
            );
          },
        },
        { label: 'Actions', id: 'actions', class: 'table-col-actions' },
      ],
      showModal: false,
    };
  }

  componentDidMount() {
    const {
      subscribeSocket: subscribeSocketProp,
      getMassNotifications: getMassNotificationsProp,
    } = this.props;

    subscribeSocketProp();
    getMassNotificationsProp();
  }

  toggleModal = () => {
    const { showModal } = this.state;

    this.setState({ showModal: !showModal });
  };

  toggleDeleteVerify = (id) => {
    const { massNotificationList } = this.props;
    const { showDeleteVerificationModal } = this.state;
    this.setState({
      showDeleteVerificationModal: !showDeleteVerificationModal,
      activeMassNotification: massNotificationList.find((n) => n.id === id),
    });
  };

  toggleVerifyModal = () => {
    const { showVerificationModal } = this.state;
    this.setState({ showVerificationModal: !showVerificationModal });
  };

  verifyNotification = (id) => {
    const { massNotificationList } = this.props;
    this.setState({
      showVerificationModal: true,
      activeMassNotification: massNotificationList.find((n) => n.id === id),
    }, () => console.log('massnot', this.state));
  };

  deleteNotification = (id) => {
    const { deleteNotification: deleteNotificationProp } = this.props;
    if (deleteNotificationProp) deleteNotificationProp(id);
  };

  getErrorReport = (id) => {
    const { downloadErrorCSV: downloadErrorCSVProp } = this.props;
    downloadErrorCSVProp(id);
  };

  // eslint-disable-next-line class-methods-use-this
  errorDownloadIsDisabled = (row) => row.Status === 'VALIDATION_COMPLETE' || row.Status === 'SENDING'
          || row.Status === 'PROCESSING' || row.Status === 'FAILED' || row.Status === 'SENT'
          || row.Status === 'TRANSCRIPT_CREATED' || row.Status === 'NO_VALID_ENTRIES' || row.Status === 'MAX_ENTRIES_SURPASSED';

  render() {
    const {
      tableColumns,
      showModal,
      showVerificationModal,
      showViewModal,
      activeMassNotification,
      showDeleteVerificationModal,
    } = this.state;
    const massNotificationHeaders = tableColumns.map((col) => <TableHeader tableId="massNotifications" key={col.id || col.label} {...col} />);
    const {
      massNotificationList,
      downloadTranscript: downloadTranscriptProp,
      downloadErrorCSV: downloadErrorCSVProp,
      setMessageCopied: setMessageCopiedProp,
    } = this.props;
    const rows = massNotificationList.map((current) => (
      <MassNotificationRow
        data={current}
        key={current.Id}
        columns={tableColumns}
        actionOptions={[{
          id: 'send',
          label: 'Send Notification',
          action: (id) => this.verifyNotification(id),
          icon: (<ChatIcon />),
          disabled: current.Status !== 'VALIDATION_COMPLETE',
        },
        {
          id: 'info',
          label: 'View Notification',
          action: (id) => {
            this.setState({
              showViewModal: true,
              activeMassNotification: massNotificationList.find((n) => n.id === id),
            });
          },
          icon: (<DocumentIcon />),
        },
        {
          id: 'download_csv',
          label: 'Download CSV Template',
          action: downloadCSVTemplate,
          icon: (<DocumentIcon />),
        },
        {
          id: 'download_error',
          label: 'Download Error Report',
          action: (id) => {
            this.getErrorReport(id);
          },
          icon: (<DocumentIcon />),
          disabled: !this.errorDownloadIsDisabled(current),
        },
        {
          id: 'download_transcript',
          label: 'Download Transcript',
          action: (id) => downloadTranscriptProp(id),
          icon: (<DocumentIcon />),
          disabled: current.Status !== 'TRANSCRIPT_CREATED',
        },
        {
          id: 'delete',
          label: 'Delete Notification',
          action: (id) => {
            this.toggleDeleteVerify(id);
          },
          icon: (<ArchiveIcon />),
        }]}
      />
    ));

    return (
      <Panel
        className="mass-notification-page"
        header={(
          <Button
            onClick={this.toggleModal}
            leftIcon={<Icon as={AddCircleIcon} />}
          >
            Create Notification

          </Button>
        )}
        title="Notifications"
      >
        <SortTable
          id="notifications"
          label="notifications"
          columns={massNotificationHeaders}
          rows={rows}
          type={massNotificationType}
        />
        <MassNotificationModal show={showModal} onHide={this.toggleModal} />
        <MassNotificationVerificationModal
          show={showVerificationModal}
          toggleShow={this.toggleVerifyModal}
          activeMassNotification={activeMassNotification}
          downloadErrorCSV={downloadErrorCSVProp}
        />
        <MassNotificationDeleteVerifyModal
          show={showDeleteVerificationModal}
          toggleShow={this.toggleDeleteVerify}
          activeMassNotification={activeMassNotification}
          deleteNotification={this.deleteNotification}
        />
        <MassNotificationViewModal
          show={showViewModal}
          hide={() => {
            this.setState({ showViewModal: false });
            setMessageCopiedProp(false);
          }}
          activeMassNotification={activeMassNotification}
          setMessageCopied={setMessageCopiedProp}
        />
      </Panel>
    );
  }
}

MassNotifications.propTypes = {
  massNotificationList: PropTypes.arrayOf(PropTypes.shape(PropTypes.Object)).isRequired,
  getMassNotifications: PropTypes.func.isRequired,
  subscribeSocket: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  downloadErrorCSV: PropTypes.func.isRequired,
  setMessageCopied: PropTypes.func.isRequired,
  downloadTranscript: PropTypes.func.isRequired,
  form: PropTypes.shape({ mode: PropTypes.string }),
  sortId: PropTypes.string,
};

MassNotifications.defaultProps = {
  sortId: null,
  form: { mode: 'isCreating' },
};

export { MassNotifications };

export const mapDispatchToProps = {
  getMassNotifications,
  subscribeSocket,
  deleteNotification,
  downloadErrorCSV,
  setMessageCopied,
  downloadTranscript,
};

const mapStateToProps = (state) => ({
  massNotificationList: _.get(state, 'massNotifications.massNotificationList', []),
});

export default connect(mapStateToProps, mapDispatchToProps)(MassNotifications);
