import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChatParticipantAddButton from './ChatParticipantAddButton';
import ChatParticipantCountContainer from './ChatParticipantCountContainer';
import ViewParticipantsButton from './ViewParticipantsButton';
import ParticipantCirclesContainer from './ParticipantCirclesContainer';
import ViewParticipantsContainer from './view-participants-container';
import './MultiPartyToolbar.less';

const MultiPartyToolbar = ({
  enableAddParticipants,
}) => {
  const [currentView, setCurrentView] = useState(null);
  const [selectedId, setSelectedId] = useState('');

  const setSelectedParticipantId = (id) => setSelectedId(id);

  const handleCloseRemoveParticipantModal = () => setCurrentView('PARTICIPANTS_VIEW');

  const handleOpenViewParticipantsModal = () => setCurrentView('PARTICIPANTS_VIEW');

  const handleCloseViewParticipantsModal = () => setCurrentView(null);

  // Shows ParticipantModal when the add participant button is clicked on ViewParticipantsModal
  const handleOpenParticipantModal = () => setCurrentView('ADD_PARTICIPANT_VIEW');

  // Shows ParticipantModal when the add button is clicked on the MultiPartyToolbar
  const handleOpenParticipantModalFromToolbar = () => setCurrentView('ADD_PARTICIPANT_FROM_TOOLBAR_VIEW');

  // Closes ParticipantModal completely when opened from MultiPartyToolbar
  // Returns back to ViewParticipantsModal when opened from ViewParticipantsModal
  const handleCloseParticipantModal = () => {
    const view = (currentView === 'ADD_PARTICIPANT_FROM_TOOLBAR_VIEW')
      ? null
      : 'PARTICIPANTS_VIEW';

    setCurrentView(view);
  };

  return (
    <div className="multi-party-toolbar">
      <div className="participants-count-and-view">
        <ChatParticipantCountContainer />
        <ViewParticipantsButton onClick={handleOpenViewParticipantsModal} />
      </div>
      <ViewParticipantsContainer
        handleCloseViewParticipantsModal={handleCloseViewParticipantsModal}
        handleCloseRemoveParticipantModal={handleCloseRemoveParticipantModal}
        handleOpenParticipantModal={handleOpenParticipantModal}
        handleCloseParticipantModal={handleCloseParticipantModal}
        currentView={currentView}
        setCurrentView={setCurrentView}
        selectedParticipantId={selectedId}
        setSelectedParticipantId={setSelectedParticipantId}
        enableAddParticipants={enableAddParticipants}
      />
      <div className="participant-circles-and-add">
        <ParticipantCirclesContainer />
        {enableAddParticipants
        && <ChatParticipantAddButton onClick={handleOpenParticipantModalFromToolbar} />}
      </div>
    </div>
  );
};

MultiPartyToolbar.propTypes = {
  enableAddParticipants: PropTypes.bool.isRequired,
};

export default MultiPartyToolbar;
