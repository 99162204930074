import {
  Button,
  Divider,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  IconButton,
  Icon,
  SimpleGrid,
  HStack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Modal, TrashIcon, InfoCircleIcon } from '@himarley/unity';
import _ from 'lodash';
import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  updateProfile as updateProfileAction,
  getListOfTitles as getListOfTitlesAction,
} from '@app/actions/profile';
import { uploadProfileImage as uploadProfileImageAction } from '@app/actions/profileImage';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import UserPhoto2 from '@app/components/UserPhoto2/UserPhoto2';
import { TITLE_ENABLED, TITLE_EDIT_ONLY_ADMIN } from '@app/constants/permissions';
import { formatPhoneNumber, cleanPhone } from '@app/helpers/format';
import { validatePhone, validateEmail } from '@app/helpers/validator';
import { StateType } from '@app/types/reducer-state';

import './GeneralInfo.less';

const trim = (value: string | number) => (typeof value === 'string' ? value.trim() : value);

const mapDispatchToProps = {
  updateProfile: updateProfileAction,
  uploadProfileImage: uploadProfileImageAction,
  getListOfTitles: getListOfTitlesAction,
};

const mapStateToProps = ({
  profile,
  ui,
  auth,
}: StateType) => ({
  ...profile,
  request: _.get(ui, 'requests.profileImage', {}),
  authUserId: _.get(auth, 'user._id'),
  isAdmin: auth?.user?.roles?.includes('ADMIN') || false,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type GeneralInfoProps = PropsFromRedux & {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  title: string;
  organizationId: string;
  contactNumber: string;
  imageUrl: string;
  readonly: boolean;
  pendingRequest: boolean;
  isAdmin: boolean;
  request: unknown;
  organization: {
    identityProvider: string;
    jit: boolean;
  };
  name?: string;
};

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  uploadProfileImage,
  readonly,
  isAdmin = false,
  getListOfTitles = () => { },
  organization = {},
  request = {},
  organizationId = '',
  firstName: propsFirstName,
  lastName: propsLastName,
  phoneNumber: propsPhoneNumber,
  contactNumber: propsContactNumber,
  imageUrl: propsImageUrl,
  email: propsEmail,
  title: propsTitle = '',
  _id,
  pendingRequest,
  updateProfile,
  name,
}) => {
  const [userErrors, setUserErrors] = useState<{
    hasError?: boolean;
    firstNameError?: boolean;
    lastNameError?: boolean;
    phoneError?: boolean;
    emailError?: boolean;
  }>({});
  const [firstName, setFirstName] = useState(propsFirstName);
  const [lastName, setLastName] = useState(propsLastName);
  const [phoneNumber, setPhoneNumber] = useState(
    cleanPhone(propsPhoneNumber),
  );
  const [contactNumber, setContactNumber] = useState(propsContactNumber);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState(propsEmail);
  const [title, setTitle] = useState(propsTitle);
  const toast = useToast();
  const toastIdRef = useRef<null | string | number>(null);

  useEffect(() => {
    getListOfTitles();
  }, [getListOfTitles, readonly]);

  useEffect(() => {
    setFirstName(propsFirstName);
    setLastName(propsLastName);
    setPhoneNumber(propsPhoneNumber);
    setContactNumber(propsContactNumber);
    setEmail(propsEmail);
    setTitle(propsTitle);
  }, [
    propsContactNumber,
    propsEmail,
    propsFirstName,
    propsLastName,
    propsPhoneNumber,
    propsTitle,
    readonly,
  ]);

  const isUserValid = (
    firstNameParam: string,
    lastNameParam: string,
    emailParam: string,
    phoneNumberParam: string,
  ) => {
    const FIRST_NAME_MIN_LENGTH = 1;
    const LAST_NAME_MIN_LENGTH = 1;

    let firstNameError = true;
    if (firstNameParam && firstNameParam.length >= FIRST_NAME_MIN_LENGTH) {
      firstNameError = false;
    }

    let lastNameError = true;
    if (lastNameParam && lastNameParam.length >= LAST_NAME_MIN_LENGTH) {
      lastNameError = false;
    }

    let emailError = true;
    if (emailParam && validateEmail(emailParam)) {
      emailError = false;
    }

    let phoneError = true;
    if (!phoneNumberParam) {
      phoneError = false;
    } else if (validatePhone(phoneNumberParam)) {
      phoneError = false;
    }

    const hasError = firstNameError || lastNameError || emailError || phoneError;

    const newUserErrors = {
      hasError,
      firstNameError,
      lastNameError,
      phoneError,
      emailError,
    };
    setUserErrors(newUserErrors);
    return hasError;
  };

  const saveProfile = () => {
    setUserErrors({});
    if (!isUserValid(firstName, lastName, email, phoneNumber)) {
      const profile = {
        _id,
        firstName: trim(firstName),
        lastName: trim(lastName),
        email: trim(email),
        phoneNumber: trim(phoneNumber),
        contactNumber: trim(contactNumber),
        title,
        organizationId,
        oldEmail: propsEmail,
      };
      updateProfile(profile, toast, toastIdRef);
      toastIdRef.current = toast({
        description: 'Saving profile...',
        status: 'loading',
        duration: null,
        variant: 'left-accent',
      });
    } else {
      toastIdRef.current = toast({
        description: 'Invalid form fields',
        status: 'error',
        duration: 5000,
        variant: 'left-accent',
      });
    }
  };

  const getSaveProfileButton = () => {
    if (pendingRequest) {
      return (
        <Button disabled>
          Saving...
        </Button>
      );
    }
    return (
      <Button
        data-testid="profile-save-button"
        onClick={saveProfile}
      >
        Save Profile
      </Button>
    );
  };

  const handleTitleChanges = (val: string) => {
    setTitle(val);
  };

  const handleFileSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = _.last(e.target.files);
      if (file) {
        toastIdRef.current = toast({
          description: 'Uploading file...',
          status: 'loading',
          duration: null,
          variant: 'left-accent',
        });
        uploadProfileImage(file, toast, toastIdRef);
      }
    },
    [uploadProfileImage, toast],
  );

  const removePhoto = useCallback(() => {
    const profile = {
      _id,
      email: propsEmail || '',
      // Required to not have chat server auto change the oktaUserId
      // from bulk upload easter egg logic
      oldEmail: propsEmail,
      ...{
        firstName,
        lastName,
        phoneNumber,
        contactNumber,
      },
      imageUrl: null,
    };
    updateProfile(profile);
    setShowModal(false);
  }, [_id, contactNumber, firstName, lastName, phoneNumber, propsEmail, updateProfile]);

  const userName = name || `${propsFirstName} ${propsLastName}`;

  const isFinishedLoading = !_.get(request, 'requesting') && _.get(request, 'success');

  const renderGeneralInfo = useMemo(
    () => (
      <VStack gap="6">
        <VStack gap="2">
          <div className="changePhotoWrap">
            <UserPhoto2
              imageUrl={propsImageUrl}
              isFinishedLoading={!!isFinishedLoading}
              id={_id}
              name={userName}
              className="user-photo-preview"
              testId="profile-page"
              isCustomer={false}
            />
            {propsImageUrl
              && (
                <IconButton
                  isRound
                  className="iconDeleteBtn"
                  colorScheme="gray"
                  onClick={() => setShowModal(true)}
                  color="gray.500"
                  icon={<Icon as={TrashIcon} />}
                  data-testid="remove-avatar-image"
                  size="sm"
                  aria-label=""
                />
              )}
          </div>
          <Tooltip label="Image must be a jpg or png under 5mb">
            <Button colorScheme="gray" variant="outline" className="file-input-wrapper" size="sm">
              Change Photo
              <input
                id="files"
                type="file"
                className="hidden-file-input"
                onChange={handleFileSelectChange}
                onClick={
                  // Allows onChange to run when exact same file is uploaded again
                  (event) => {
                    const target = event.target as HTMLInputElement;
                    target.value = '';
                  }
                }
                data-testId="change-avatar-button"
                title="" // Hides tooltip that browsers show for selected file
              />
            </Button>
          </Tooltip>
        </VStack>
        <VStack gap="3" mb="10" align="center">
          <Heading size="md" mb="0">{userName}</Heading>
          {propsTitle && (
            <Heading size="sm" mb="0" color="gray.500">{propsTitle}</Heading>
          )}
        </VStack>
      </VStack>
    ),
    [propsImageUrl, isFinishedLoading, _id, userName, handleFileSelectChange, propsTitle],
  );

  const renderModal = useMemo(
    () => (
      <Modal
        show={showModal}
        title="Are you sure you want to delete your image?"
        showCloseButton
        toggleModal={() => setShowModal(false)}
      >
        <Text mb="10">
          You cannot undo this action.
          You may upload a new image by clicking on the &quot;Change photo&quot; link.
        </Text>
        <Flex justify="end">
          <Button
            colorScheme="red"
            onClick={removePhoto}
            data-testid="remove-avatar-image-confirmation"
          >
            Delete Photo
          </Button>
        </Flex>
      </Modal>
    ),
    [removePhoto, showModal],
  );

  const isAbleToEditEmail = useMemo(
    () => {
      const isSSOUser = organization?.identityProvider === 'FEDERATED';
      const isJITUser = organization?.jit;
      const { EDIT_EMAIL_ENABLED } = process.env;
      return isAdmin && ((isSSOUser && !isJITUser && EDIT_EMAIL_ENABLED) || !isSSOUser);
    },
    [organization?.identityProvider, organization?.jit, isAdmin],
  );

  const isTitleEnabled = usePermissionVerify({ neededPermissions: [TITLE_ENABLED] });
  const isTitleEditAdminOnly = usePermissionVerify({
    neededPermissions: [TITLE_EDIT_ONLY_ADMIN],
  });

  const hasAccessToEditTitles = useMemo(() => {
    const { hasAccess: adminOnly } = isTitleEditAdminOnly;
    return adminOnly ? isAdmin : true;
  }, [isTitleEditAdminOnly, isAdmin]);

  const canEditTitle = useMemo(
    () => isTitleEnabled.hasAccess && hasAccessToEditTitles,
    [isTitleEnabled, hasAccessToEditTitles],
  );

  return (
    <>
      {renderModal}
      <div className="general-info">
        {renderGeneralInfo}
        {isAbleToEditEmail && (
          <HStack align="center" mb="10" gap="2">
            <Icon as={InfoCircleIcon} />
            <Text color="gray.500" m="0">Changing a user email will log them out when you click Save</Text>
          </HStack>
        )}
        <SimpleGrid columns={2} spacing="10" mb="4">
          <FormControl isInvalid={userErrors.firstNameError}>
            <FormLabel>First Name (Required)</FormLabel>
            <Input
              data-testid="profile-edit-first-name"
              id="first-name"
              onChange={(e) => setFirstName(e.target.value)}
              isInvalid={userErrors.firstNameError}
              value={firstName}
              required
            />
            <FormErrorMessage>First Name is Required</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={userErrors.lastNameError}>
            <FormLabel>Last Name (Required)</FormLabel>
            <Input
              data-testid="profile-edit-last-name"
              id="last-name"
              onChange={(e) => setLastName(e.target.value)}
              isInvalid={userErrors.lastNameError}
              value={lastName}
              required
            />
            <FormErrorMessage>Last Name is Required</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={userErrors.emailError}>
            <FormLabel>Email (Required)</FormLabel>
            <Input
              data-testid="profile-edit-email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={userErrors.emailError}
              value={email}
              disabled={!isAbleToEditEmail}
              required
            />
            <FormErrorMessage>Invalid Email</FormErrorMessage>
          </FormControl>
          {
            canEditTitle && (
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  data-testid="operators-title"
                  onChange={(e) => handleTitleChanges(e.target.value)}
                  value={title}
                />
              </FormControl>
            )
          }
          <FormControl isInvalid={userErrors.phoneError}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              data-testid="profile-edit-phone-number"
              id="phone"
              onChange={(e) => setPhoneNumber(e.target.value)}
              isInvalid={userErrors.phoneError}
              value={formatPhoneNumber(phoneNumber)}
              required
            />
            <FormErrorMessage>Invalid Phone Number</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Office Phone Number</FormLabel>
            <Input
              data-testid="profile-edit-contact-number"
              id="contactNumber"
              onChange={(e) => setContactNumber(e.target.value)}
              value={contactNumber}
            />
          </FormControl>
        </SimpleGrid>
        <Divider my="10" />
        <Flex gap={4} justify="flex-end" align="center">
          {getSaveProfileButton()}
        </Flex>
      </div>
    </>
  );
};

export default connector(GeneralInfo);
