/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';
import Modal from '../../../elements/Modal/Modal';
import DateInput from '../../../elements/form/DateInput/DateInput';
import TimeInput from '../../../elements/form/TimeInput/TimeInput';
import TextAreaInput from '../../../elements/form/TextAreaInput/TextAreaInput';
import ButtonRow from '../../../elements/buttons/ButtonRow/ButtonRow';
import './ScheduleMessage.less';
import { formatTimeToAMPM } from '../../../../helpers/datetime';
import { setTemplateTrayIsOpen } from '../../../../actions/ui';
import { templatesSlice } from '../../../../slices/templates';

const thisDate = new Date();
const MAX_DAYS = 90;
const MIN_DATE = thisDate.toISOString();
const MAX_DATE = (new Date(thisDate.setDate(thisDate.getDate() + MAX_DAYS))).toISOString();

/**
 * Create Scheduled Message
 * @param   {string} chatId active chat id
 * @param   {string} jobId active job id
 * @param   {function} queueMessage function for creating a scheduled message
 * @param   {function} toggleShow function triggered with show is modified
 * @param   {boolean} show show modal
* */
const ScheduleMessage = ({
  chatId, jobId, queueMessage, show, toggleShow,
  scheduledTime, setScheduledTime,
  scheduledDate, setScheduledDate,
}) => {
  const { clearLoadedTemplate } = templatesSlice.actions;
  const [scheduledMsgText, setScheduledMsgText] = useState('');

  // close this modal and reset date, time and message text
  const closeAndReset = () => {
    toggleShow(false);
    setScheduledMsgText('');
    setScheduledDate();
    setScheduledTime();
  };
  const dispatch = useDispatch();

  const isDisabled = !scheduledDate || !scheduledTime || !scheduledMsgText;

  const handleSubmit = () => {
    const eventTime = new Date(`${scheduledDate}, ${scheduledTime}`).getTime();
    const formObject = { message: scheduledMsgText, eventTime };
    closeAndReset();
    queueMessage({
      ...formObject,
      chatId,
      jobId,
    });
  };

  const { context, loadedTemplateText } = useSelector(({ ui, templates }) => ({
    context: ui.context,
    loadedTemplateText: templates.loadedTemplateText,
  }));

  useEffect(() => {
    if (context === 'SCHEDULED_MESSAGE' && loadedTemplateText) {
      setScheduledMsgText(loadedTemplateText);
      dispatch(clearLoadedTemplate());
    }
  }, [loadedTemplateText, context, dispatch]);

  return (
    <Modal title="Schedule Message" show={show} toggleModal={closeAndReset} data-test="schedule-message-header">
      <div className="formInputWrap">
        <DateInput
          required
          label="Date"
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          value={scheduledDate}
          onChange={(id) => setScheduledDate(id ? id.split('T')[0] : '')}
        />
        <TimeInput
          required
          label="Time"
          value={scheduledTime || 'Select Time'}
          defaultTime={scheduledTime && formatTimeToAMPM(scheduledTime)}
          onSelect={(id) => setScheduledTime(id)}
          filterAfterSelect={false}
        />
        <button
          type="button"
          onClick={() => dispatch(setTemplateTrayIsOpen(true, 'SCHEDULED_MESSAGE'))}
          data-testid="scheduled-msg-choose-template"
          className="link-button-small"
        >
          + Choose Message Template

        </button>
      </div>
      <TextAreaInput
        required
        onChange={(e) => setScheduledMsgText(e.target.value)}
        placeholder="Write message to schedule..."
        value={scheduledMsgText}
      />
      <ButtonRow>
        <Button variant="outline" onClick={closeAndReset}>
          Cancel
        </Button>
        <Button isDisabled={isDisabled} onClick={handleSubmit}>
          Schedule Message
        </Button>
      </ButtonRow>
    </Modal>
  );
};

ScheduleMessage.propTypes = {
  chatId: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
  queueMessage: PropTypes.func.isRequired,
  scheduledDate: PropTypes.string.isRequired,
  scheduledTime: PropTypes.string.isRequired,
  setScheduledDate: PropTypes.func.isRequired,
  setScheduledTime: PropTypes.func.isRequired,
};

export default ScheduleMessage;
