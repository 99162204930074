/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import MarleyIcon from '../icons/MarleyIcon';
import Notification from '../Notification/Notification';
import { setConfigItem, muteAudio } from '../../actions/ui';
import { logout } from '../../actions/auth';
import {
  getAutoReplies,
  toggleAutoAssign,
} from '../../actions/profile';
import TooltipInfo from '../elements/Tootip/TooltipInfo';
import SlideToggle from '../SlideToggle/SlideToggle';
import { checkPermissionsContains } from '../../helpers/common';
import { AUTO_ASSIGN_PERMISSIONS } from '../../constants/general';
import './Header.less';
import MuteIcon from '../icons/MuteIcon';
import { ProfileDropdown } from './ProfileDropdown';

const Header = (props) => {
  const {
    showProfileConfig,
    setConfigItem,
    permissions,
    toggleAutoAssign,
    logout,
    muteAudio,
    muted,
    getAutoReplies,
    authUserId,
    disableAway,
    profile,
    isAway,
    awayMessage,
  } = props;

  useEffect(() => {
    getAutoReplies(authUserId);
  }, [authUserId]);

  const isAutoAssign = _.get(profile, 'isAutoAssign', false);
  return (
    <header className="app-header">
      <div className="app-header-inner">
        <div className="header-left">
          {process.env.REMOVE_WELCOME_PAGE_ENABLED ? (
            <div className="icon-box">
              {!showProfileConfig && <MarleyIcon />}
            </div>
          ) : (
            <Link className="icon-box" to="/home">
              {!showProfileConfig && <MarleyIcon />}
            </Link>
          )}
        </div>
        <Notification />
        <div className="header-profile" to="/profile">
          {checkPermissionsContains(permissions, AUTO_ASSIGN_PERMISSIONS) && (
            <div className="user-auto-assign-block">
              <div className="auto-assign" data-testid="auto-assign-cases">
                Auto-Assign
                <TooltipInfo
                  message="Will auto-assign cases from queue"
                  placement="bottom"
                />
              </div>
              <SlideToggle on={isAutoAssign} onToggle={toggleAutoAssign} testId="auto-assign-btn" />
            </div>
          )}
          <Button
            className="mute-button"
            bsStyle="link"
            onClick={() => muteAudio(!muted)}
          >
            <MuteIcon muted={muted} />
          </Button>
          <ProfileDropdown
            disableAway={disableAway}
            profile={profile}
            authUserId={authUserId}
            awayMessage={awayMessage}
            setConfigItem={setConfigItem}
            isAway={isAway}
            logout={logout}
          />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  permissions: PropTypes.arrayOf(String),
  setConfigItem: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  authUserId: PropTypes.string.isRequired,
  showProfileConfig: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  muteAudio: PropTypes.func.isRequired,
  getAutoReplies: PropTypes.func.isRequired,
  muted: PropTypes.bool,
  toggleAutoAssign: PropTypes.func.isRequired,
  disableAway: PropTypes.bool.isRequired,
  isAway: PropTypes.bool.isRequired,
  awayMessage: PropTypes.instanceOf(Object).isRequired,
};

Header.defaultProps = {
  permissions: [],
  muted: false,
};

Header.contextTypes = {
  router: PropTypes.instanceOf(Object).isRequired,

};

export const mapDispatchToProps = {
  getAutoReplies,
  toggleAutoAssign,
  setConfigItem,
  muteAudio,
  logout,
};

const mapStateToProps = (state) => {
  const awayMessage = _.get(state.auth, 'userAutoReply.generalAutoReplies.list.0', {});
  const isAway = _.get(state.auth, 'userStatus', 'active') === 'away';
  return {
    score: _.get(state.auth, 'score'),
    authUserId: state.auth.user?._id,
    profile: state.auth.user,
    permissions: _.get(state.auth, 'permissions', []),
    showProfileConfig: state.ui && state.ui.showProfileConfig,
    muted: state.ui && state.ui.muted,
    isAway,
    awayMessage,
  };
};

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
export { connectedHeader };

export default withRouter(connectedHeader);
