import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
} from '@chakra-ui/react';
import React from 'react';

import ViewParticipantList from './view-participant-list';

interface Participant {
  id: string;
  imageUrl: string;
  name: string;
  role: string;
  relationship: string;
}

interface ViewParticipantsModalProps {
  activeParticipants: Participant[];
  pendingParticipants: Participant[];
  inactiveParticipants: Participant[];
  loggedInOperatorId: string;
  primaryEndUserId: string;
  onClose: () => void;
  onClickRemoveParticipant: (participantId: string) => void;
  onClickAddParticipant: () => void;
  onClickEditParticipant: (participantId: string) => void;
  onClickReinviteParticipant: (participantId: string) => void;
  enableAddParticipants: boolean;
}

const ViewParticipantsModal: React.FC<ViewParticipantsModalProps> = ({
  activeParticipants,
  pendingParticipants,
  inactiveParticipants,
  loggedInOperatorId,
  primaryEndUserId,
  onClickRemoveParticipant,
  onClose,
  onClickAddParticipant,
  onClickEditParticipant,
  onClickReinviteParticipant,
  enableAddParticipants,
}) => (
  <Modal
    size="xl"
    isOpen
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent minW="978px">
      <ModalHeader>Participants</ModalHeader>
      <ViewParticipantList
        activeParticipants={activeParticipants}
        pendingParticipants={pendingParticipants}
        inactiveParticipants={inactiveParticipants}
        loggedInOperatorId={loggedInOperatorId}
        primaryEndUserId={primaryEndUserId}
        onClickRemoveParticipant={onClickRemoveParticipant}
        onClose={onClose}
        onClickAddParticipant={onClickAddParticipant}
        onClickEditParticipant={onClickEditParticipant}
        onClickReinviteParticipant={onClickReinviteParticipant}
        enableAddParticipants={enableAddParticipants}
      />
    </ModalContent>
  </Modal>
);

export default ViewParticipantsModal;
