import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';

const ViewParticipantsButton = ({ onClick }) => (
  <Button
    variant="outline"
    size="xs"
    className="view-participants-button"
    onClick={onClick}
    data-testid="view-participants"
    ml="2"
  >
    View
  </Button>
);

ViewParticipantsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ViewParticipantsButton;
